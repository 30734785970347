@import '/node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300&display=swap');

.team-page-monsters {
    width: 100%;
    background: #322759;
    margin-top: -2%;
    margin-bottom: -7%;
    overflow: hidden;
    object-fit: contain;
    object-position: center;
    align-items: center;
    display: flex;
}
.team-page-monsters-mobile {
    display: none;
}
.team-page-intros {
    margin-top: 55px;
    margin-bottom: 55px;
    text-align: center;
}
.team-page-banners {
    font-family: "filson-pro";
    font-style: normal;
    font-size: 64px;
    font-weight: 700;
    line-height: 84px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
    justify-items: center;
    display: grid;
}
.team-page-banners-underline {
    width: 550px;
    border: 3.5px solid #E29B5D;
    opacity: 1;
    justify-content: center;
    align-items: center;  
}
.flip-card-outer {
    perspective: 1000px;
    width: 300px;
    height: 400px;
    margin: 25px, 0;
    margin-right: 5px;
    margin-left: 5px;

    .flip-card-inner {
        transform-style: preserve-3d;
        transition: .3s linear .1s;
        position: relative;
        width: inherit;
        height: inherit;
        cursor: pointer; 

        .flip-card-outer:focus {
            outline: none !important;
        }

        .flip-card-outer:active {
            outline: none !important;
        }

        &.showBack {
            transform: rotateY(180deg);
        }

        .card {
            backface-visibility: hidden;
            position: absolute;

            width: 100%;
            height: 100%;
            background-color: #322759;

            border: none;

            &.front {
                transform: rotateY(0);
            }

            &.back {
                transform: rotateY(180deg);

            }
        }
    }

}
.css-1f8sh1y {
    width: 1500px !important;
    height: 730px !important;  
}    
.desktop-advisors {
    display: block; 
}
.mobile-advisors {
    display: none;
}
.no-arrows {
    display: none;
}
.arrows {
    display: block;
}
.advisor-header {
    font-family: Roboto;
    font-style: normal;
    border-bottom: 1px solid #fff;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    // identical to box height
    color: #FFFFFF;
}
.advisor-title {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
}
.advisor-description {
    font-family: Roboto;
    font-style: normal;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    color: #FFFFFF;
}
.advisor-card {
    width: 370px; 
    height: 690px; 
    perspective: 1px;
    align-items: center;
    border-radius: 30px; 
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25); 
}

@media screen and (max-width: 2000px) {
    .css-1f8sh1y {
        width: 1450px !important;
        height: 730px !important;
    }   
    .no-arrows {
        display: none;
    }
    .arrows {
        display: block;
    }
    .desktop-advisors {
        display: block;
    }
    .mobile-advisors {
        display: none;
    }
    .advisor-header {
        font-family: Roboto;
        font-style: normal;
        border-bottom: 1px solid #fff;
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        // identical to box height
        color: #FFFFFF;
    }
    .advisor-title {
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
    }
    .advisor-description {
        font-family: Roboto;
        font-style: normal;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        color: #FFFFFF;
    }
    .advisor-card {
        width: 370px; 
        height: 690px; 
        perspective: 1px;
        align-items: center;
        border-radius: 30px; 
        box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25); 
    }
    .team-page-intros {
        margin-top: 55px;
        margin-bottom: 55px;
        text-align: center;
    }
    .team-page-banners {
        font-family: "filson-pro";
        font-style: normal;
        font-size: 64px;
        font-weight: 700;
        line-height: 84px;
        letter-spacing: 0em;
        text-align: center;
        color: white;
    }
    .team-page-banners-underline {
        width: 550px;
        border: 3.5px solid #E29B5D;
        opacity: 1;
        justify-content: center;
    }
    /* team-page-profile-cards */
    .flip-card-outer {
        perspective: 1000px;
        width: 300px;
        height: 400px;
        margin: 25px, 0;
        margin-right: 5px;
        margin-left: 5px;
        .flip-card-inner {
            transform-style: preserve-3d;
            transition: .3s linear .1s;
            position: relative;
            width: inherit;
            height: inherit;
            .flip-card-outer:focus {
                outline: none !important;
            }
            .flip-card-outer:active {
                outline: none !important;
            }
            &.showBack {
                transform: rotateY(180deg);
            }
            .card {
                backface-visibility: hidden;
                position: absolute;

                width: 100%;
                height: 100%;
                background-color: #322759;

                border: none;

                &.front {
                    transform: rotateY(0);
                }

                &.back {
                    transform: rotateY(180deg);

                }
            }
        }

    }
}

@media screen and (max-width: 1600px) {
    .css-1f8sh1y {
        width: 1270px !important;
        height: 670px !important;
    } 
    .no-arrows {
        display: none;
    }
    .arrows {
        display: block;
    }
    .advisor-header {
        font-family: Roboto;
        font-style: normal;
        border-bottom: 1px solid #fff;
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        // identical to box height
        color: #FFFFFF;
    }
    .advisor-title {
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
    }
    .advisor-description {
        font-family: Roboto;
        font-style: normal;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #FFFFFF;
    }
    .advisor-card {
        width: 310px; 
        height: 630px; 
        perspective: 1px;
        align-items: center;
        border-radius: 30px; 
        box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25)
    }
}

@media screen and (max-width: 1400px) {
    .css-1f8sh1y {
        width: 1100px !important;
        height: 750px !important;
    } 
    .no-arrows {
        display: none;
    }
    .arrows {
        display: block;
    }
    .advisor-header {
        font-family: Roboto;
        font-style: normal;
        border-bottom: 1px solid #fff;
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        // identical to box height
        color: #FFFFFF;
    }
    .advisor-title {
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
    }
    .advisor-description {
        font-family: Roboto;
        font-style: normal;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #FFFFFF;
    }
    .advisor-card {
        width: 250px; 
        height: 700px; 
        perspective: 1px;
        align-items: center;
        border-radius: 30px; 
        box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25)
    }
}

@media screen and (max-width: 1200px) {
        
    .team-page-intros {
        margin-top: 35px;
        margin-bottom: 45px;
    }
    .team-page-banners {
        font-family: "filson-pro";
        font-size: 34px;
        font-weight: 700;
        line-height: 84px;
        letter-spacing: 0em;
        text-align: center;
        color: white;
    }
    .team-page-banners-underline {
        justify-content: center;
        width: 300px;
        border: 3px solid #E29B5D;
        opacity: 1;
        margin-top: -4px;
    }
    .flip-card-outer {
        perspective: 1000px;
        width: 300px;
        height: 400px;
        margin: 10px, 0;
        margin-right: 1px;
        margin-left: 1px;
        .flip-card-outer:focus {
            outline: none !important;
        }
        .flip-card-outer:active {
            outline: none !important;
        }
        .flip-card-inner {
            transform-style: preserve-3d;
            transition: .3s linear .1s;
            position: relative;
            width: inherit;
            height: inherit;
            &.showBack {
                transform: rotateY(180deg);
            }
            .card {
                backface-visibility: hidden;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #322759;
                border: none;
                &.front {
                    transform: rotateY(0);
                }
                &.back {
                    transform: rotateY(180deg);

                }
            }
        }

    }
    .css-1f8sh1y {
        width: 670px !important;
        height: 730px !important;
    }    
    .no-arrows {
        display: none;
    }
    .arrows {
        display: block;
    }
    .desktop-advisors {
        display: none;
    }
    .mobile-advisors {
        display: block;
    }
    .advisor-header {
        font-family: Roboto;
        font-style: normal;
        border-bottom: 1px solid #fff;
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        // identical to box height
        color: #FFFFFF;
    }
    .advisor-title {
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
    }
    .advisor-description {
        font-family: Roboto;
        font-style: normal;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        color: #FFFFFF;
    }
    .advisor-card {
        width: 370px; 
        height: 690px; 
        perspective: 1px;
        align-items: center;
        border-radius: 30px; 
        box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25)
    }
}

@media screen and (max-width: 750px) {
    .css-1f8sh1y {
        width: 610px !important;
        height: 700px !important;
    }    
    .no-arrows {
        display: none;
    }
    .arrows {
        display: block;
    }
    .desktop-advisors {
        display: none;
    }
    .mobile-advisors {
        display: block;
    }
    .advisor-header {
        font-family: Roboto;
        font-style: normal;
        border-bottom: 1px solid #fff;
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        // identical to box height
        color: #FFFFFF;
    }
    .advisor-title {
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
    }
    .advisor-description {
        font-family: Roboto;
        font-style: normal;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #FFFFFF;
    }
    .advisor-card {
        width: 350px; 
        height: 640px; 
        perspective: 1px;
        align-items: center;
        border-radius: 30px; 
        box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25)
    }
} 

@media screen and (max-width: 690px) {
    .css-1f8sh1y {
        width: 380px !important;
        height: 700px !important;
    }    
    .no-arrows {
        display: block;
    }
    .arrows {
        display: none;
    }
    .desktop-advisors {
        display: none;
    }
    .mobile-advisors {
        display: block;
    }
    .advisor-header {
        font-family: Roboto;
        font-style: normal;
        border-bottom: 1px solid #fff;
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        // identical to box height
        color: #FFFFFF;
    }
    .advisor-title {
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
    }
    .advisor-description {
        font-family: Roboto;
        font-style: normal;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #FFFFFF;
    }
    .advisor-card {
        width: 350px; 
        height: 640px; 
        perspective: 1px;
        align-items: center;
        border-radius: 30px; 
        box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25)
    }
} 

@media screen and (max-width: 420px) {
    .css-1f8sh1y {
        width: 310px !important;
        height: 740px !important;
    }
    .no-arrows {
        display: block;
    }
    .arrows {
        display: none;
    }
    .desktop-advisors {
        display: none;
    }
    .mobile-advisors {
        display: block;
    }
    .advisor-card {
        width: 250px; 
        height: 700px; 
        perspective: 1px;
        align-items: center;
        border-radius: 30px; 
        box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25)
    }
    .team-page-monsters {
        display: none;
    }
    .team-page-monsters-mobile {
        display: block;
        margin-top: 30%;
        width: 100%;
        margin-bottom: -30%;
        background: #322759;
    }
}