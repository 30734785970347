/*********************************************************************************************

Project : rwdgrid - responsive grid system for your next project
URI: http://rwdgrid.com/
Version: 2.0
Author: Vineeth G S
Author URI: http://www.gsvineeth.com
Github URI: https://github.com/gsvineeth/rwdgrid/

**********************************************************************************************

1.  Base code
2.  Mobile Grid
3.  Grid 720px+
4.  Grid 1024px+
5.  Grid 1200px+
6.  Helper classes

**********************************************************************************************/


/*********************************************************************************************

1.  Base code

*********************************************************************************************/


*, *:after, *:before {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

[class*='grid-'] {
    /* float: left; */
    /* padding: 10px; */
    width:100%;
}

.clear-grid{
    /* clear:both;
    float:inherit; */
}
.container {
    /* padding: 0% 10%; */
    margin:0%;
    width: 100%;
}
.container:after, .container:before {
    /* display: table; */
    /* clear:both; */
    content: " ";
}
.nest {
    margin:0 -10px;
    padding:0;
    width:auto;
}

/*********************************************************************************************

2.  Mobile Grid

*********************************************************************************************/


.grid-m-12 {
    width: 100%;
}
.grid-m-11 {
    width: 91.666663%;
}
.grid-m-10 {
    width: 83.33%;
}
.grid-m-9 {
    width: 74.999997%;
}
.grid-m-8 {
    width: 66.66666664%;
}
.grid-m-7 {
    width: 58.333%;
}
.grid-m-6 {
    width: 50%;
}
.grid-m-5 {
    width: 41.6665%;
}
.grid-m-4 {
    width: 33.33%;
}
.grid-m-3 {
    width: 24.99%;
}
.grid-m-2 {
    width: 16.66666%;
}
.grid-m-1 {
    width: 8.33%;
}

/*********************************************************************************************

3.  Grid 720px+

*********************************************************************************************/

@media (min-width: 720px) {
    .grid-12 {
        width: 100%;
    }
    .grid-11 {
        width: 91.666663%;
    }
    .grid-10 {
        width: 83.33%;
    }
    .grid-9 {
        width: 74.999997%;
    }
    .grid-8 {
        width: 66.66666664%;
    }
    .grid-7 {
        width: 58.333%;
    }
    .grid-6 {
        width: 50%;
    }
    .grid-5 {
        width: 41.6665%;
    }
    .grid-4 {
        width: 33.33%;
    }
    .grid-3 {
        width: 24.99%;
    }
    .grid-2 {
        width: 16.66666%;
    }
    .grid-1 {
        width: 8.33%;
    }

    .grid-t-12 {
        width: 100%;
    }
    .grid-t-11 {
        width: 91.666663%;
    }
    .grid-t-10 {
        width: 83.33%;
    }
    .grid-t-9 {
        width: 74.999997%;
    }
    .grid-t-8 {
        width: 66.66666664%;
    }
    .grid-t-7 {
        width: 58.333%;
    }
    .grid-t-6 {
        width: 50%;
    }
    .grid-t-5 {
        width: 41.6665%;
    }
    .grid-t-4 {
        width: 33.33%;
    }
    .grid-t-3 {
        width: 24.99%;
    }
    .grid-t-2 {
        width: 16.66666%;
    }
    .grid-t-1 {
        width: 8.33%;
    }
}

/*********************************************************************************************

4.  Grid 1024px+

*********************************************************************************************/

@media only screen and (min-width: 1024px) {
    .grid-tl-12 {
        width: 100%;
    }
    .grid-tl-11 {
        width: 91.666663%;
    }
    .grid-tl-10 {
        width: 83.33%;
    }
    .grid-tl-9 {
        width: 74.999997%;
    }
    .grid-tl-8 {
        width: 66.66666664%;
    }
    .grid-tl-7 {
        width: 58.333%;
    }
    .grid-tl-6 {
        width: 50%;
    }
    .grid-tl-5 {
        width: 41.6665%;
    }
    .grid-tl-4 {
        width: 33.33%;
    }
    .grid-tl-3 {
        width: 24.99%;
    }
    .grid-tl-2 {
        width: 16.66666%;
    }
    .grid-tl-1 {
        width: 8.33%;
    }
}


/*********************************************************************************************

5.  Grid 1200px+

*********************************************************************************************/

@media only screen and (min-width: 1440px) {
    .container {
        /* max-width: 2560px; */
        margin:0 auto;
    }
    .container-fluid {
        max-width:90%;
        margin:0 5%;
    }
    .grid-d-12 {
        width: 100%;
    }
    .grid-d-11 {
        width: 91.666663%;
    }
    .grid-d-10 {
        width: 83.33%;
    }
    .grid-d-9 {
        width: 74.999997%;
    }
    .grid-d-8 {
        width: 66.66666664%;
    }
    .grid-d-7 {
        width: 58.333%;
    }
    .grid-d-6 {
        width: 50%;
    }
    .grid-d-5 {
        width: 41.6665%;
    }
    .grid-d-4 {
        width: 33.33%;
    }
    .grid-d-3 {
        width: 24.99%;
    }
    .grid-d-2 {
        width: 16.66666%;
    }
    .grid-d-1 {
        width: 8.33%;
    }
}

/*********************************************************************************************

6.  Helper classes

*********************************************************************************************/


img.scale, img.responsive {
    max-width: 100%;
    height: auto;
}
