@import '/node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300&display=swap');



    .about-values-quote {
        font-family: Roboto;
        font-style: italic;
        font-size: 36px;
        text-align: center;
        line-height: 42px;
        color: #D3BEFF;
        letter-spacing: 0em;
        font-weight: 300;
    }

    .about-values {
        margin-top: 55px;
        margin-bottom: 55px;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }

    .mission-and-vision-header {
        font-family: 'filson-pro';
        font-style: normal;
        font-weight: 700;
        font-size: 55px;
        line-height: 55px;
        padding-top: 40px;
        padding-left: 24px;
        padding-bottom: 15px;
        color: #FFFFFF;
      }
      .mission-and-vision-underline {
        width: 578px;
        height: 0px;
        margin-left: 24px;
        border: 4px solid #E29B5D;
      }
      .mission-and-vision-text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 200;
        font-size: 30px;
        line-height: 39px;
        /* or 47px */
        margin-right: 40px;
        margin-top: 0px;
        //padding-top: 15px;
        text-align: right;
        color: #FFFFFF;
      }

      .vision-text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 200;
        font-size: 30px;
        line-height: 39px;
        /* or 47px */
        margin-right: 26px;
        padding-left: 40px;
        margin-top: 0px;
        //padding-top: 15px;
        text-align: left;
        color: #FFFFFF;
      }

      .mission-desktop-br {
        display: block;
      }
      .mission-tablet-br {
        display: none;
      }
      .mission-mobile-br {
        display: none;
      }
      .mission-monster-desktop {
        display: block
      }
      .mission-monster-tablet {
        display: none
      }
      .mission-monster-mobile {
        display: none
      }
      .mission-card {
        width: 658px;
        height: 418px;
        border-radius: 22px;
        background: #800D15;
      }
      .vision-card {
        width: 658px;
        height: 427px;
      }
      
      .vision-monsters-mobile {
        display: none
      }
      
      .vision-monsters-tablet {
        display: none
      }
      
      .vision-monsters-desktop {
        display: block
      }

    .values-header {
        font-family: 'filson-pro';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        color: #FFFFFF;
    }

    .values-text {
        font-family: 'roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #FFFFFF;
    }

    .value-card {
        width: 445px;
        height: 200px;
        border-radius: 30px;
        margin: 10px;
        box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
        padding: 0px
    }

    .value-img {
        height: 200px;
    }

    .value-text {
        width: 245px;
        height: 200px;
        padding-left: 21px;
        padding-right: 29px;
    }

    .values-display {
        width: 50%;
    }

    .about-values-quote-bold {
        font-family: Roboto, light;
        font-style: italic;
        font-size: 36px;
        text-align: center;
        line-height: 42px;
        color: #EFDAFF;
        letter-spacing: 0em;
        font-weight: 600;
    }

    .about-page-banners-underline {
        width: 390px;
        border: 3.5px solid #E29B5D;
        opacity: 1;
        margin-left: 200px;
        margin-bottom: 50px;
        align-items: center;
        justify-content: center;
    }

    .about-page-triangle {
        margin-top: -500px;
        //padding-bottom: 100px;
        z-index: 0;
    }

    .about-cards {
        width: 450px;
        height: 501.2px;
        align-items: center;
        justify-content: center;
    }

    .about-cards-title {
        font-family: 'filson-pro';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 36px;
        text-align: center;
        margin-bottom: 10px;
        padding-top: 20px;
        padding-bottom: 10px;
        padding-left: 50px;
        padding-right: 50px;
        border-bottom: 4px solid #E29B5D;
        color: white;
    }

    .about-cards-description {
        padding: 5px;
        padding-bottom: 0px;
        font-family: Roboto;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 0px;
        color: white;
    }

    .about-card-img-desktop {
        display: block;
    }

    .about-card-img-tablet {
        display: none;
    }

    .about-card-img-mobile {
        display: none;
    }

    .about-card-vid-desktop {
        display: block;
    }

    .about-card-vid-tablet {
        display: none;
    }

    .about-card-vid-mobile {
        display: none;
    }


@media screen and (max-width: 1200px) {

    .about-values {
        margin-top: 25px;
        margin-bottom: 45px;
        z-index: 1;
        align-items: center;
        justify-content: center;
    }

    .about-values-quote {
        font-family: Roboto;
        font-style: italic;
        font-size: 25px;
        text-align: center;
        line-height: 56.25px;
        color: #D3BEFF;
        letter-spacing: 0em;
        font-weight: 10;
        padding-left: 110px;
        padding-right: 110px;

    }

    .about-values-quote-bold {
        font-family: Roboto, light;
        font-style: italic;
        font-size: 25px;
        text-align: center;
        line-height: 56.25px;
        color: #EFDAFF;
        letter-spacing: 0em;
        font-weight: 600;
    }

    .about-page-banners-underline {
        justify-content: center;
        align-items: center;
        width: 390px;
        border: 3px solid #E29B5D;
        opacity: 1;
        margin-top: -4px;
        margin-right: 200px;
        margin-bottom: 20px;
    }

    .about-page-triangle {
        margin-top: -100px;
        margin-bottom: 100px;
        z-index: 0;
    }

    .about-cards {
        width: 450px;
        height: 531.2px;
        align-items: center;
        justify-content: center;
    }

    .about-cards-title {
        font-family: 'filson-pro';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 36px;
        text-align: center;
        margin-bottom: 10px;
        padding-top: 20px;
        padding-bottom: 10px;
        padding-left: 50px;
        padding-right: 50px;
        border-bottom: 4px solid #E29B5D;
        color: white;
    }

    .about-cards-description {
        padding: 5px;
        padding-bottom: 0px;
        font-family: Roboto;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 0px;
        color: white;
    }

    .about-card-img-desktop {
        display: none;
    }

    .about-card-img-tablet {
        display: block;
    }

    .about-card-img-mobile {
        display: none;
    }

    .about-card-vid-desktop {
        display: none;
    }

    .about-card-vid-tablet {
        display: block;
    }

    .about-card-vid-mobile {
        display: none;
    }

    .value-card {
        width: 329px;
        height: 151px;
        border-radius: 30px;
        margin: 10px;
        box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
        padding: 0px
    }

    .values-header {
        font-family: 'filson-pro';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 18px;

        color: #FFFFFF;
    }

    .value-img {
        height: 151px;
    }

    .value-text {
        width: 180px;
        height: 165px;
        padding-left: 21px;
        padding-right: 29px;
    }

    .values-text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

        color: #FFFFFF;
    }

    .mission-and-vision-header {
        font-family: 'filson-pro';
        font-style: normal;
        font-weight: 700;
        font-size: 55px;
        line-height: 55px;
        padding-top: 40px;
        padding-left: 24px;
        padding-bottom: 15px;
      
        color: #ffffff;
      }
      .mission-and-vision-underline {
        width: 578px;
        height: 0px;
        margin-left: 24px;
      
        border: 4px solid #E29B5D;
      }
      
      .mission-and-vision-text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 200;
        font-size: 30px;
        line-height: 39px;
        /* or 47px */
        margin-right: 40px;
        margin-top: 0px;
        //padding-top: 15px;
        text-align: right;
      
        color: #FFFFFF;
      }
      .mission-desktop-br {
        display: none;
      }
      .mission-tablet-br {
        display: block;
      }
      .mission-mobile-br {
        display: none;
      }
      .mission-monster-desktop {
        display: none
      }
      
      .mission-monster-tablet {
        display: block
      }
      
      .mission-monster-mobile {
        display: none
      }
      
      .mission-card {
        width: 658px;
        height: auto;
        border-radius: 22px;
      
        background: #800D15;
      }
      .vision-card {
        width: 658px;
        height: 429px;
    
      }
    
      .vision-monsters-mobile {
        display: none
      }
    
      .vision-monsters-tablet {
        display: block
      }
    
      .vision-monsters-desktop {
        display: none
      }

}

@media screen and(max-width: 414px) {
    /*about-page*/

    .about-values-quote {
        font-family: Roboto;
        font-style: italic;
        text-align: center;
        font-weight: 300;
        font-size: 28px;
        line-height: 33px;
        color: #D3BEFF;
        letter-spacing: 0em;
    }

    .about-values-quote-bold {
        font-family: Roboto, light;
        font-style: italic;
        font-weight: 600;
        font-size: 28px;
        line-height: 33px;
        text-align: center;
        color: #EFDAFF;
        letter-spacing: 0em;
    }

    .mission-and-vision-header {
        font-family: 'filson-pro';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 36px;
        padding-top: 18px;
        padding-left: 8px;
        padding-bottom: 6px;
      
        color: #ffffff;
      } 
      .mission-and-vision-underline {
        width: 285.79px;
        height: 0px;
        margin-left: 8px;
      
        border: 4px solid #E29B5D;
      }
      
      .mission-and-vision-text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 290;
        font-size: 24px;
        line-height: 31px;
        /* or 47px */
        margin-left: 24px;
        margin-top: 0px;
        //padding-top: 15px;
        text-align: left;
      
        color: #FFFFFF;
      }

      .vision-text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 290;
        font-size: 24px;
        line-height: 31px;
        /* or 47px */
        margin-right: 26px;
        padding-left: 20px;
        margin-top: 0px;
        //padding-top: 15px;
        text-align: left;
        color: #FFFFFF;
      }

      .mission-desktop-br {
        display: none;
      }
      .mission-tablet-br {
        display: none;
      }
      .mission-mobile-br {
        display: block;
      }
      .mission-monster-desktop {
        display: none
      }
      
      .mission-monster-tablet {
        display: none
      }
      
      .mission-monster-mobile {
        display: block
      }
      
      .mission-card {
        width: 340px;
        height: 460px;
        border-radius: 22px;
      
        background: #800D15;
      }
      .vision-card {
        width: 327px;
        height: 400px;
      }
    
      .vision-monsters-mobile {
        display: block
      }
    
      .vision-monsters-tablet {
        display: none
      }
    
      .vision-monsters-desktop {
        display: none
      }

      .about-cards {
        width: 328px;
        height: 640px;
        align-items: center;
        justify-content: center;
    }

    .about-cards-title {
        font-family: 'filson-pro';
        font-style: normal;
        font-weight: 700;
font-size: 36px;
line-height: 42px;
        text-align: center;
        margin-bottom: 10px;
        padding-top: 20px;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 10px;
        border-bottom: 4px solid #E29B5D;
        color: white;
    }

    .about-cards-description {
        padding: 15px;
        padding-bottom: 0px;
        font-family: Roboto;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 0px;
        color: white;
    }

    .about-card-img-desktop {
        display: none;
    }

    .about-card-img-tablet {
        display: none;
    }

    .about-card-img-mobile {
        display: block;
    }

    .about-card-vid-desktop {
        display: none;
    }

    .about-card-vid-tablet {
        display: none;
    }

    .about-card-vid-mobile {
        display: block;
    }

    .about-page-banners-underline {
      justify-content: center;
      align-items: center;
      width: 220px;
      border: 3px solid #E29B5D;
      opacity: 1;
      margin-top: -4px;
      margin-right: 200px;
      margin-bottom: 20px;
  }
}