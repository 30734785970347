@import '/node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300&display=swap');

.App {
  max-width: 100%;
  overflow-x: hidden;
  text-align: left;
  font-size: 16px;
  h1,
  h2,
  h3,
  h4 {
    font-weight: bold;
  }
  section {
    padding: 5%;
    margin-top: -10px; 
  }
} 
button {
  all: unset;
}
a {
  text-decoration: none;
  color: white;
}
p {
  line-height: 1.4;
  font-weight: 300;
}
#quote {
  width: 80%;
  margin: 10% auto;
  text-align: center;
  font-style: italic;
}
.page-header {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 45px;
  padding-bottom: 40px;
  color: white;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  position: relative;
  overflow: visible;
  z-index: 4;
  .logo {
    position: absolute;
    width: 15%;
    display: block;
  }
  .mobile-logo {
    display: none;
  }
  .page-header-links {
    z-index: 5;
  }
}
.page-triangle-banner {
  vertical-align: top;
  height: 200px;
}
.half-circle {
  padding: 2%;
  margin-top: -5.5% !important;
  width: 70%;
  border-bottom-right-radius: 800px;
  border-bottom-left-radius: 800px;
  border: 15px solid rgb(255, 51, 218);
  border-top: 0;
}
footer {
  background: top / cover no-repeat url(./images/footer-background-short.png);
  padding: 3% 8%;
  background-color: rgb(50, 40, 89);
  color: white;
  // position: absolute;
  width: 100%;
  // z-index: 1;
}
.divider {
  align-self: center;
  align-items: center;
  background: #FF3369;
  height: 10px;
}
/*page-header*/
.sub-title {
  font-family: 'filson-pro';
  font-style: normal;
  font-weight: 10;
  font-size: 20px;
  line-height: 34px;
}
.mainNav:link {
  color: #FFF;
  text-decoration: none;
}
.mainNav:visited {
  color: #FFF;
  text-decoration: none;
}
.mainNav:hover {
  color: white;
  text-decoration: none;
}
.mainNav:active {
  color: white;
  text-decoration: none;
}
.activeRoute {
  background-color: white;
}
.mobile-break {
  display: none !important;
}
.desktop-break {
  display: block;
}
.nav-bar-mobile {
  display: none;
}
.nav-bar-desktop {
  display: block;
}
@media screen and (min-width: 414px) and (max-width: 900px) {
  .nav-bar-mobile {
    display: none;
  }
  .nav-bar-desktop {
    display: block;
  }
  .page-header {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 45px;
    padding-bottom: 40px;
    color: white;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    position: relative;
    overflow: visible;
    z-index: 4;
    .logo {
      position: absolute;
      width: 25%;
      display: block;
    }
    .page-header-links {
      z-index: 5;
    }
  }
}
@media screen and(max-width: 414px) {
  /*nav-bar-desktop*/
  .mainNav {
    display: none;
  }
  /*nav-bar-mobile*/
  .page-header {
    background: #322759;
    width: 100%;
    height: 150px;
    position: fixed;
  .logo {
      display: none;
    }
  .mobile-logo {
      display: block;
      position: fixed;
      width: 30%;
      margin-top: -65px;
      margin-left: -40px;
    }
  .nav-bar {
      position: absolute;
      z-index: 0;
      margin-top: -1000px;
    }
  .page-header-links {
      z-index: 0;
    }
  }
  .nav-bar-mobile {
    position: relative;
    display: block;
  }

  .nav-bar-mobile button {
    position: fixed;
    right: 40px;
    top: 40px;
    z-index: 2;
    cursor: pointer;
    background: transparent;
    border: none;
  }
  .menuNav {
    overflow-y: none;
    list-style: none;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 0;
    overflow: hidden;
    max-width: 414px;
    margin: 0;
    touch-action: none;
    text-align: center;
  }
  .menuNav.showMenu {
    width: 100%;
    right: 0px;
    height: 100vh;
    padding-top: 120px;
    position: fixed;
    background: #322759;
    .mobile-nav-monster {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 50vw;
      justify-content: flex-start;
    }
  }
  .nav-tabs {
    padding: 20px;
    border-bottom: none !important;
    text-decoration: none;
    color: #D9D8D8;
  }
  .menuNav:link {
    color: #D9D8D8;
  }
  .menuNav:visited {
    color: #D9D8D8;
  }
  .menuNav:active {
    color: #E87DA6;
  }
  .hamburger-close {
    color: #FFFFFF;
    width: 40px;
    height: 40px;
    margin-top: 10px;
    transform: scale(1.7);
  }
  .hamburger-open {
    color: #FFFFFF;
    width: 40px;
    height: 40px;
    margin-top: 10px;
    transform: scale(1.7);
  }
  .page-triangle-banner {
    vertical-align: top;
    height: 200px;
    margin-top: 100px;
  }
  .mobile-break {
    display: block !important;
  }
  .desktop-break {
    display: none !important;
  }
}