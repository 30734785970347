@import '/node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300&display=swap');



    .tutorial-video-panel {
        width: 401px;
        height: 427px;
        background: #581F59;
        box-shadow: 3px -4px 10px #4B2671, -5px 4px 15px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
    }

    .tutorial-videos-header-text {
        font-family: "filson-pro";
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 30px;
        color: #FFFFFF;
        padding-top: 47px;
        padding-bottom: 47px;
        padding-left: 48px;
    }

    .tutorial-videos-panel {
        width: 401px;
        height: 315px;
        background: #712773;
        border-radius: 0px 0px 20px 20px;
        justify-content: space-evenly;
        padding-top: 42px;
    }

    .tutorial-videos-text {
        font-family: 'filson-pro';
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 25px;
        padding-left: 48px;
        //padding-top: 45px;
        margin-top: 5px;
        //margin-top: 10px;
        width: 353px;
        height: 70px;
        color: #FFFFFF;


    }

    .tutorial-videos-text:hover {
        background: rgba(252, 143, 255, 0.42);
    }

    .tutorial-videos-text:focus {
        background: rgba(252, 143, 255, 0.42);
    }

    .overview-video-heading {
        font-family: "filson-pro";
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 30px;
        color: #FFFFFF;
        letter-spacing: 0em;
    }

    .overview-video {
        width: 860px;
        height: 483px;
    }

    .error-analysis-tablet {
        display: none
    }

    .error-analysis-not-tablet {
        display: block
    }

    .error-analysis {
        margin-bottom: 0px;
    }

    .error-analysis-desktop-green-monster {
        margin-top: 227px;
        width: 250px;
    }

    .error-analysis-desktop-pink-monster {
        width: 250px;
     
    }

    .error-analysis-mobile-green-monster {
        display: none;
    }

    .error-analysis-mobile-pink-monster {
        display: none;
    }

    .error-analysis-header {
        font-family: 'filson-pro';
        font-style: normal;
        font-weight: 700;
        font-size: 55px;
        line-height: 55px;
        text-align: right;
        color: #FFFFFF;
        display: block;
    }

    .error-analysis-header-mobile {
        display: none;
    }

    .error-analysis-underline {
        width: 650px;
        border: 4px solid #E29B5D;
        opacity: 1;
        //justify-content: right;
        //align-items: flex-end;
        margin-bottom: 0px;
    }

    .error-analysis-description {
        font-family: "Roboto";
        font-size: 28px;
        font-weight: 400;
        line-height: 38px;
        letter-spacing: 0em;
        text-align: right;
        color: #FFFFFF;
        margin-top: 0px;
        padding: 0px;
        margin-right: 0px;
        margin-top: 15px;
    }

    .error-analysis-description-mobile {
        display: none;
    }

    .error-analysis-link {
        color: #FFB8D3;
    }

    .error-analysis-link:hover {
        color: #FFFFFF;
    }

    .error-analysis-speech {
        width: 282px;
        height: 90px;
        border-radius: 15px;
        background: #FFFFFF;
        margin-bottom: 30px;
        padding: 10px;
    }

    .error-analysis-activity {
        width: 380px;
        margin-left: 100px;
        border-radius: 15px !important;
        padding: 5px;
        overflow: hidden;
    }

    .error-analysis-activity-text {
        font-family: 'Kalam';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 38px;
        letter-spacing: 0.15px;
        text-align: center;

        color: #000000;
    }

    .error-analysis-activity-text-mobile {
        display: none;
    }

    .error-analysis-activity-answer {
        font-family: 'Kalam';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.15px;
        display: flex;
        align-items: center;
        text-align: center;
        padding: 0px;
        margin: 0px;
        color: #E87DA6;
    }

    .error-analysis-activity-button {
        font-family: 'filson-soft';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.15px;

        color: #E87DA6;
    }

    .crmt-section {
        display: flex;
        justify-content: left;
    }

    .crmt-header {
        font-family: 'filson-pro';
        font-style: normal;
        //font-weight: 700;
        font-size: 55px;
        line-height: 66px;
        text-align: left;
        color: #FFFFFF;
        display: block;
    }

    .crmt-underline {
        width: 640px;
        border: 4px solid #E29B5D;
        opacity: 1;
        margin-bottom: 0px;
    }

    .crmt-header-mobile {
        display: none;
    }

    .crmt-description {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
        z-index: 1;
        margin-left: 10px;
    }

    .crmt-description-bold {
        font-family: 'Roboto';
        font-style: italic;
        line-height: 38px;
        letter-spacing: 0em;
        text-align: left;
        font-weight: 700;
        font-size: 28px;
        color: #FFFFFF;
    }

    .crmt-pioneers {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 26px;
        line-height: 38px;

        color: #FFB8D3;
    }

    .crmt-pioneers:hover {
        color: #FFFFFF;
    }

    .crmt-diagram {
        margin-top: 100px;
    }

    .teach-like-a-champ-section {
        display: flex;
        justify-content: left;
    }

    .teach-like-champ-header {
        font-family: 'filson-pro';
        font-style: normal;
        font-weight: 700;
        font-size: 55px;
        line-height: 55px;
        letter-spacing: 0em;
        color: #FFFFFF;
        text-align: center;
    }

    .teach-like-champ-underline {
        width: 650px;
        border: 4px solid #E29B5D;
        opacity: 1;
        margin-bottom: 0px;
    }

    .teach-like-champ-description {
        font-family: "Roboto";
        font-size: 28px;
        font-weight: 400;
        line-height: 38px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
    }

    .teach-like-champ-link {
        color: #FFB8D3;
        font-style: italic;
    }

    .teach-like-champ-link:hover {
        color: #FFFFFF;
    }

    .rough-draft-card {
        width: 100%;
        height: 330px;
        padding: 20px;
    }

    .rough-draft-card-content {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 500;
        font-size: 24px;
        line-height: 27px;
        color: #FFFFFF;
    }

    .rough-draft-card-content-bold {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 700;
        font-size: 24px;
        line-height: 27px;
        color: #FFFFFF;
    }

    .rough-draft-link {
        color: #FFB8D3;
    }

    .rough-draft-link:hover {
        color: #FFFFFF;
    }

    .rough-draft-monster {
        width: 252.67px;
        height: 198px;
    }

    .game-show-section {
        display: flex;
        justify-content: left;
    }

    .game-show-align {
        display: flex;
        justify-content: left;
        margin-bottom: 30px; 
        margin-top: 20px;
    }
    
    .game-show-header {
        font-family: 'filson-pro';
        font-style: normal;
        font-weight: 700;
        font-size: 55px;
        line-height: 66px;
        text-align: left;
        color: #FFFFFF;
    }

    .game-show-header-underline {
        width: 650px;
        border: 4px solid #E29B5D;
        opacity: 1;
        margin-bottom: 0px;
    }

    .game-show-video {
        margin-bottom: 70px;
        width: 860px;
        height: 483px;
    }

    .first-description-above {
        display: none;
    }

    .first-description-below {
        display: block;
    }

    .game-show-description1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 38px;
        color: #FFFFFF;
        letter-spacing: 0em;
        text-align: left;
    }

    .game-show-underline {
        width: 172px;
        border: 1px solid #FFFFFF;
        opacity: 1;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .game-show-description2 {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: #FFFFFF;
    }

    .game-show-link {
        color: #FFB8D3;
    }

    .game-show-link:hover {
        color: #FFFFFF;
    }

    .tablet-br {
        display: none
    }

    .small-desk-break {
        display: none;
    }

    .small-desk-breaks {
        display: none;
    }

@media screen and (max-width: 1480px) {
    .error-analysis-desktop-green-monster {
        margin-top: 227px;
        width: 205px;
    }

    .error-analysis-desktop-pink-monster {
        width: 205px;
    }

    .error-analysis-mobile-green-monster {
        display: none;
    }

    .error-analysis-mobile-pink-monster {
        display: none;
    }

    .error-analysis-header-mobile {
        display: none;
    }

    .error-analysis-description-mobile {
        display: none;
    }

    .error-analysis-speech {
        width: 260px;
        height: 90px;
        border-radius: 15px;
        background: #FFFFFF;
        margin-bottom: 30px;
        padding: 10px;
    }

    .error-analysis-activity {
        width: 324px;
        margin-left: 55px;
        border-radius: 15px !important;
        padding: 5px;
        overflow: hidden;
    }

    .error-analysis-activity-text-mobile {
        display: none;
    }

    .tablet-br {
        display: none
    }

    .small-desk-break {
        display: none;
    }

}   

@media screen and (max-width: 1470px) {

    .error-analysis-tablet {
        display: none
    }

    .error-analysis-not-tablet {
        display: block
    }

    .crmt-section {
        display: flex;
        justify-content: left;
    }

    .crmt-diagram {
        margin-top: 100px;
    }

    .rough-draft-card {
        width: 100%;
        height: 330px;
        padding: 20px;
    }

    .rough-draft-card-content {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 500;
        font-size: 24px;
        line-height: 27px;
        color: #FFFFFF;
    }

    .rough-draft-card-content-bold {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 700;
        font-size: 24px;
        line-height: 27px;
        color: #FFFFFF;
    }

    .rough-draft-monster {
        width: 252.67px;
        height: 198px;
    }

    .game-show-section {
        display: flex;
        justify-content: left;
    }

    .game-show-align {
        display: flex;
        justify-content: left;
        margin-bottom: 30px; 
    }
    
    .game-show-header {
        font-family: 'filson-pro';
        font-style: normal;
        font-weight: 700;
        font-size: 55px;
        line-height: 66px;
        text-align: left;
        color: #FFFFFF;
    }

    .game-show-header-underline {
        width: 650px;
        border: 4px solid #E29B5D;
        opacity: 1;
        margin-bottom: 0px;
    }

    .game-show-video {
        margin-bottom: 70px;
        width: 700px;
        height: 393px;
    }

    .first-description-above {
        display: none;
    }

    .first-description-below {
        display: block;
    }

    .game-show-description1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 38px;
        color: #FFFFFF;
        letter-spacing: 0em;
        text-align: left;
    }

    .game-show-underline {
        width: 172px;
        border: 1px solid #FFFFFF;
        opacity: 1;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .game-show-description2 {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: #FFFFFF;
    }

    .tablet-br {
        display: none
    }

    .small-desk-break {
        display: none;
    }

}

@media screen and (max-width: 1290px) {

    .error-analysis-tablet {
        display: block
    }

    .error-analysis-not-tablet {
        display: none
    }

    .overview-video-heading {
        font-family: "filson-pro";
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 30px;
        color: #FFFFFF;
        letter-spacing: 0em;
        margin-top: 70px;
    }

    .overview-video {
        width: 640px;
        height: 360px;
    }

    .desktop-break {
        display: none;
    }

    .tablet-br {
        display: block
    }

    .small-desk-break {
        display: none;
    }

    .monsters {
        width: 700px;
    }

    .error-analysis-activity {
        width: 270px;
        margin-left: 40px;
        border-radius: 15px !important;
        padding: 0px;
        margin-top: 40px;
        overflow: hidden;
    }

    .error-analysis-header {
        font-family: 'filson-pro';
        font-style: normal;
        font-weight: 700;
        font-size: 55px;
        line-height: 55px;
        text-align: center;
        color: #FFFFFF;
        display: block;
        width: 700px;
    }

    .error-analysis-underline {
        width: 700px;
        border: 4px solid #E29B5D;
        opacity: 1;
        //justify-content: right;
        //align-items: flex-end;
        margin-bottom: 0px;
    }

    .error-analysis-speech {
        width: 200px;
        height: 90px;
        border-radius: 15px;
        background: #FFFFFF;
        margin-bottom: 30px;
        padding: 10px;
    }

    .error-analysis-activity-button {
        font-family: 'filson-soft';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.15px;
        margin-bottom: 0px !important;
        color: #E87DA6;
    }

    .error-analysis-activity-answer {
        font-family: 'Kalam';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 29px;
        letter-spacing: 0.15px;
        display: flex;
        align-items: center;
        text-align: center;
        padding: 0px;
        margin: 0px;
        color: #E87DA6;
    }

    .error-analysis-description {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 28px;
        line-height: 33px;
        letter-spacing: 0em;
        text-align: right;
        color: #FFFFFF;
        margin-top: 0px;
        padding: 0px;
        margin-right: 0px;
        margin-top: 15px;
        width: 605px;
    }

    .error-analysis-activity-text {
        font-family: 'Kalam';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 29px;
        letter-spacing: 0.15px;
        text-align: center;
        margin-bottom: 0px !important;

        color: #000000;
    }

    .error-analysis-desktop-green-monster {
        margin-top: 65px;
        width: 180px;
        margin-bottom: 10px;
    }

    .error-analysis-desktop-pink-monster {
        width: 180px;
        height: 140px;
        margin-top: 175px;
        margin-left: 120px;
        margin-bottom: 10px;
    }

    .crmt-section {
        display: flex;
        justify-content: center;
    }

    .crmt-diagram {
        margin-top: 30px !important;
        width: 500px;
    }

    .teach-like-a-champ-section {
        display: flex;
        justify-content: center;
    }

    .teach-like-champ-header {
        font-family: 'filson-pro';
        font-style: normal;
        font-weight: 700;
        font-size: 55px;
        line-height: 55px;
        letter-spacing: 0em;
        color: #FFFFFF;
        text-align: center;
    }

    .teach-like-champ-underline {
        width: 650px;
        border: 4px solid #E29B5D;
        opacity: 1;
        margin-bottom: 0px;
    }

    .teach-like-champ-description {
        font-family: "Roboto";
        font-size: 28px;
        font-weight: 400;
        line-height: 38px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
        width: 605px;
    }

    .rough-draft-card {
        width: 100%;
        height: 330px;
        padding: 20px;
    }

    .rough-draft-card-content {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 500;
        font-size: 24px;
        line-height: 27px;
        color: #FFFFFF;
        text-align: right;
    }

    .rough-draft-card-content-bold {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 700;
        font-size: 24px;
        line-height: 27px;
        color: #FFFFFF;
        text-align: right;
    }

    .rough-draft-monster {
        width: 252.67px;
        height: 198px;
    }


    .game-show-section {
        display: flex;
        justify-content: center;
    }

    .game-show-align {
        display: flex;
        justify-content: center;
        margin-bottom: 30px; 
    }
    
    .game-show-header {
        font-family: 'filson-pro';
        font-style: normal;
        font-weight: 700;
        font-size: 55px;
        line-height: 55px;
        text-align: left;
        color: #FFFFFF;
    }

    .game-show-header-underline {
        width: 580px;
        border: 4px solid #E29B5D;
        opacity: 1;
        margin-bottom: 0px;
    }

    .game-show-video {
        margin-bottom: 30px;
        width: 607px;
        height: 389px;
    }

    .first-description-above {
        display: block;
    }

    .first-description-below {
        display: none;
    }

    .game-show-description1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 38px;
        color: #FFFFFF;
        letter-spacing: 0em;
        text-align: center;
    }

    .game-show-underline {
        width: 172px;
        border: 1px solid #FFFFFF;
        opacity: 1;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .game-show-description2 {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: #FFFFFF;
    }
}

@media screen and (max-width: 1130px) {
    .desktop-break {
        display: none;
    }

    .teach-like-a-champ-section {
        display: flex;
        justify-content: center;
    }

    .rough-draft-card {
        width: 100%;
        height: 330px;
        padding: 20px;
    }

    .rough-draft-card-content {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 500;
        font-size: 24px;
        line-height: 27px;
        color: #FFFFFF;
        text-align: right;
    }

    .rough-draft-card-content-bold {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 700;
        font-size: 24px;
        line-height: 27px;
        color: #FFFFFF;
        text-align: right;
    }

    .rough-draft-monster {
        width: 252.67px;
        height: 198px;
    }
}

@media screen and (max-width: 700px) {
    .desktop-break {
        display: none;
    }

    .tablet-br {
        display: none;
    }

    .small-desk-breaks {
        display: block;
    }

    .small-desk-break {
        display: block;
    }

    .tutorial-video-panel {
        width: 328px;
        height: 285px;
        background: #581F59;
        box-shadow: 3px -4px 10px #4B2671, -5px 4px 15px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        margin: 28px;
    }

    .tutorial-videos-header-text {
        font-family: "filson-pro";
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 36px;
        color: #FFFFFF;
        padding-top: 30px;
        padding-bottom: 25px;
        padding-left: 48px;
    }

    .tutorial-videos-panel {
        width: 328px;
        height: 210px;
        background: #712773;
        border-radius: 0px 0px 20px 20px;
        justify-content: space-evenly;
        padding-top: 20px;
    }

    .tutorial-videos-text {
        font-family: 'filson-pro';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        padding-left: 48px;
        //padding-top: 45px;
        margin-top: 5px;
        //margin-top: 10px;
        width: 280px;
        height: 50px;
        color: #FFFFFF;
    }

    .overview-video-heading {
        font-family: "filson-pro";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #FFFFFF;
        letter-spacing: 0em;
        margin-top: 70px;
    }

    .overview-video {
        margin-top: -20px;
        width: 328px;
        height: 184px;
    }

    .monsters {
        width: 400px;
    }

    .error-analysis-activity {
        width: 260px;
        margin-left: -135px;
        border-radius: 15px !important;
        padding: 0px;
        margin-top: 125px;
        overflow: hidden;
    }

    .error-analysis-header {
        font-family: 'filson-pro';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 36px;
        text-align: center;
        color: #FFFFFF;
        display: block;
        width: 360px;
    }

    .error-analysis-underline {
        width: 360px;
        border: 4px solid #E29B5D;
        opacity: 1;
        margin-bottom: 0px;
    }

    .error-analysis-speech {
        width: 180px;
        height: 80px;
        border-radius: 15px;
        background: #FFFFFF;
        margin-bottom: 30px;
        padding: 10px;
    }

    .error-analysis-activity-button {
        font-family: 'filson-soft';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.15px;
        margin-bottom: 0px !important;
        color: #E87DA6;
    }

    .error-analysis-activity-answer {
        font-family: 'Kalam';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 29px;
        letter-spacing: 0.15px;
        display: flex;
        align-items: center;
        text-align: center;
        padding: 0px;
        margin: 0px;
        color: #E87DA6;
    }

    .error-analysis-description {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 18px;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
        margin-top: 0px;
        padding: 0px;
        margin-right: 0px;
        margin-top: 15px;
        width: 345px;
    }

    .error-analysis-activity-text {
        font-family: 'Kalam';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 29px;
        letter-spacing: 0.15px;
        text-align: center;
        margin-bottom: 0px !important;
        padding-bottom: 0px !important;

        color: #000000;
    }

    .error-analysis-desktop-green-monster {
        margin-top: 15px;
        width: 130px;
        height: 180px;
        margin-bottom: 10px;
    }

    .error-analysis-desktop-pink-monster {
        width: 130px;
        height: 100px;
        margin-top: 70px;
        margin-left: 265px;
        margin-bottom: 10px;
    }

    .crmt-section {
        display: flex;
        justify-content: center;
    }

    .crmt-header {
        font-family: 'filson-pro';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 36px;
        text-align: left;
        color: #FFFFFF;
        display: block;
    }

    .crmt-header-mobile {
        display: none;
    }

    .crmt-underline {
        width: 410px;
        border: 4px solid #E29B5D;
        opacity: 1;
        margin-bottom: 0px;
    }

    .crmt-description {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
        z-index: 1;
        margin-left: 10px;
        width: 390px;
    }

    .crmt-description-bold {
        font-family: 'Roboto';
        font-style: italic;
        line-height: 38px;
        letter-spacing: 0em;
        text-align: left;
        font-weight: 800;
        font-size: 24px;
        line-height: 28px;
        color: #FFFFFF;
    }

    .crmt-pioneers {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 28px;

        color: #FFB8D3;
    }

    .crmt-pioneers:hover {
        color: #FFFFFF;
    }

    .crmt-diagram {
        margin-top: 100px;
    }

    .teach-like-a-champ-section {
        display: flex;
        justify-content: center;
    }

    .teach-like-champ-header {
        font-family: 'filson-pro';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 36px;
        letter-spacing: 0em;
        color: #FFFFFF;
        text-align: center;
    }

    .teach-like-champ-underline {
        width: 380px;
        border: 4px solid #E29B5D;
        opacity: 1;
        margin-bottom: 0px;
    }

    .teach-like-champ-description {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0em;
        margin-left: 10px!important;
        text-align: left;
        color: #FFFFFF;
        width: 370px;
    }

    .rough-draft-card {
        width: 100%;
        height: 330px;
        padding: 20px;
    }

    .rough-draft-card-content {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        color: #FFFFFF;
        text-align: right;
        padding-left: 0px !important;
        margin-bottom: 0px !important;
    }

    .rough-draft-card-content-bold {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        color: #FFFFFF;
        text-align: right;
    }

    .rough-draft-monster {
        width: 252.67px;
        height: 198px;
    }

    .game-show-section {
        display: flex;
        justify-content: center;
    }

    .game-show-align {
        display: flex;
        justify-content: center;
        margin-bottom: 10px; 
    }
    
    .game-show-header {
        font-family: 'filson-pro';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 36px;
        text-align: left;
        color: #FFFFFF;
    }

    .game-show-header-underline {
        width: 380px;
        border: 4px solid #E29B5D;
        opacity: 1;
        margin-bottom: 0px;
    }

    .game-show-video {
        margin-bottom: 30px;
        width: 337px;
        height: 218.81px;
    }

    .first-description-above {
        display: block;
    }

    .first-description-below {
        display: none;
    }

    .game-show-description1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 30px;
        color: #FFFFFF;
        letter-spacing: 0em;
        text-align: center;
    }

    .game-show-underline {
        width: 172px;
        border: 1px solid #FFFFFF;
        opacity: 1;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .game-show-description2 {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: #FFFFFF;
    }
}

@media screen and (max-width: 625px) {
    .rough-draft-card {
        width: 100%;
        height: 330px;
        padding: 20px;
    }

    .rough-draft-monster {
        width: 252.67px;
        height: 198px;
    }
}

@media screen and (max-width: 550px) {
    .rough-draft-card {
        width: 100%;
        height: 330px;
        padding: 20px;
    }

    .rough-draft-monster {
        width: 252.67px;
        height: 198px;
    }
}

@media screen and (max-width: 500px) {
    .rough-draft-card {
        width: 100%;
        height: 330px;
        padding: 20px;
    }

    .rough-draft-monster {
        width: 252.67px;
        height: 198px;
    }
}

@media screen and (max-width: 450px) {
    .rough-draft-card {
        width: 100%;
        height: 330px;
        padding: 20px;
    }

    .rough-draft-monster {
        width: 252.67px;
        height: 198px;
    }
}

@media screen and(max-width: 414px) {

    /*resources-page*/
    .tutorial-video-panel {
        margin-top: 40px;
        width: 328px;
        height: 285px;
        background: #581F59;
        box-shadow: 3px -4px 10px #4B2671, -5px 4px 15px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        margin-bottom: 100px;
    }

    .tutorial-videos-header-text {
        font-family: "filson-pro";
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 30px;
        color: #FFFFFF;
        padding-top: 47px;
        //padding-bottom: 47px;
        padding-left: 48px;
    }

    .tutorial-videos-panel {
        width: 328px;
        height: 285px;
        background: #712773;
        border-radius: 0px 0px 20px 20px;
        justify-content: space-evenly;
        padding-top: 42px;
    }

    .tutorial-videos-text {
        font-family: 'filson-pro';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        //padding-left: 48px;
        //padding-top: 45px;
        margin-top: 5px;
        //margin-top: 10px;
        // width: 353px;
        // height: 70px;
        color: #FFFFFF;


    }

    .tutorial-videos-text:hover {
        background: rgba(252, 143, 255, 0.42);
        width: 280px;
    }

    .tutorial-videos-text:focus {
        background: rgba(252, 143, 255, 0.42);
        width: 280px;
    }

    .overview-video-heading {
        font-family: "filson-pro";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #FFFFFF;
        letter-spacing: 0em;
        margin-left: 5px;
    }

    .overview-video {
        width: 328px;
        height: 184px;
        //margin-left: 5px;
    }

    .error-analysis-tablet {
        display: none
    }

    .error-analysis-not-tablet {
        display: block
    }

    .error-analysis {
        margin-bottom: 0px;
    }

    .error-analysis-desktop-green-monster {
        display: none;
    }

    .error-analysis-desktop-pink-monster {
        display: none;
    }

    .error-analysis-mobile-green-monster {
        display: block;
        margin-top: -168px;
        margin-left: -135px;
    }

    .error-analysis-mobile-pink-monster {
        display: block;
        margin-top: -120px;
        margin-left: 280px;
    }


    .error-analysis-header {
        display: none;
    }

    .error-analysis-header-mobile {
        font-family: 'filson-pro';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 36px;
        text-align: center;
        color: #FFFFFF;
        display: block;
        margin-top: -100px;
    }

    .error-analysis-underline {
        display: none;
    }

    .error-analysis-underline-mobile {
        width: 316px;
        height: 0px;
        border: 4px solid #E29B5D;
        opacity: 1;
        // margin-bottom: 10px;
        // margin-top: 20px;
        margin-left: 30px;
    }

    .error-analysis-description {
        display: none;
    }

    .error-analysis-description-mobile {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
        padding-left: 20px;
        padding-right: 20px;
        display: block;
    }

    .error-analysis-link {
        color: #FFB8D3;
    }

    .error-analysis-link:hover {
        color: #FFFFFF;
    }

    .error-analysis-speech {
        display: none;
    }

    .error-analysis-speech-mobile {
        width: 240px;
        height: 80px;
        border-radius: 15px;
        background: #FFFFFF;
        padding: 10px;
        margin-left: 100px;
        margin-top: 100px;

    }

    .error-analysis-activity {
        width: 257px;
        border-radius: 15px !important;
        padding: 5px;
        overflow: hidden;
        margin-left: 30px;
        margin-top: -100px;
    }

    .error-analysis-activity-text {
        font-family: 'Kalam';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 29px;
        letter-spacing: 0.15px;
        text-align: center;
        color: #000000;
        //display: block;
    }

    .error-analysis-activity-text-mobile {
        font-family: 'Kalam';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 29px;
        letter-spacing: 0.15px;
        text-align: center;
        color: #000000;
        display: block;
    }

    .error-analysis-activity-answer {
        font-family: 'Kalam';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 29px;
        letter-spacing: 0.15px;
        display: flex;
        align-items: center;
        text-align: center;
        padding: 0px;
        margin: 0px;

        color: #E87DA6;
    }

    .error-analysis-activity-button {
        font-family: 'filson-soft';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.15px;
        color: #E87DA6;
    }

    .crmt-section {
        display: flex;
        justify-content: center;
    }

    .crmt-underline {
        width: 315px;
        border: 4px solid #E29B5D;
        opacity: 1;
        margin-bottom: 0px;
        margin-left: 0px;
    }

    .crmt-header {
        display: none;
    }

    .crmt-header-mobile {
        font-family: 'filson-pro';
        font-style: normal;
        //font-weight: 700;
        font-weight: 700;
        font-size: 36px;
        line-height: 36px;
        width: 100% !important;
        margin-right: 0px !important;
        text-align: center;
        color: #FFFFFF;
        display: block;
    }

    .crmt-description {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
        z-index: 1;
        margin-left: 10px;
        width: 297px;
    }

    .crmt-description-bold {
        font-family: 'Roboto';
        font-style: italic;
        letter-spacing: 0em;
        text-align: left;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        color: #FFFFFF;
    }

    .crmt-pioneers {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 380;
        font-size: 24px;
        line-height: 28px;
        color: #FFB8D3;
    }

    .crmt-pioneers:hover {
        color: #FFFFFF;
    }

    .crmt-diagram {
        width: 325.51px;
        height: 337px;
    }

    .teach-like-a-champ-section {
        display: flex;
        justify-content: center;
    }

    .teach-like-champ-header {
        font-family: 'filson-pro';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 36px;
        letter-spacing: 0em;
        color: #FFFFFF;
        text-align: center;
    }

    .teach-like-champ-underline {
        width: 311px;
        border: 4px solid #E29B5D;
        opacity: 1;
        margin-bottom: 0px;
    }

    .teach-like-champ-description {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
        width: 297px;
    }

    .rough-draft-card {
        width: 100%;
        height: 330px;
        padding: 20px;
    }

    .rough-draft-card-content {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        color: #FFFFFF;
        text-align: right;
    }

    .rough-draft-card-content-bold {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        color: #FFFFFF;
        text-align: right;
    }

    .rough-draft-monster {
        width: 120px;
        height: 94px;
    }

    .game-show-header {
        font-family: 'filson-pro';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 36px;
        text-align: center;
        color: #FFFFFF;
    }

    .game-show-header-underline {
        width: 350px;
        border: 4px solid #E29B5D;
        opacity: 1;
        margin-bottom: 15px;
        margin-left: 10px;
    }

    .game-show-video {
        margin-bottom: 30px;
        width: 328px;
        height: 184px;
        margin-top: 100px;
        justify-content: center;
    }

    .first-description-above {
        display: block;
    }

    .first-description-below {
        display: none;
    }

    .game-show-description1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 30px;
        color: #FFFFFF;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 0px;
        margin-bottom: -125px;
    }

    .game-show-underline {
        width: 172px;
        border: 1px solid #FFFFFF;
        opacity: 1;
        margin-top: 50px;
        margin-bottom: 50px;
        display: none;
    }

    .game-show-description2 {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        margin-top: -20px;
        color: #FFFFFF;
    }

    .game-show-link {
        color: #FFB8D3;
    }

    .game-show-link:hover {
        color: #FFFFFF;
    }

    .game-show-align {
        display: flex;
        justify-content: center;
        margin-bottom: 5px; 
    }

    .first-description-above {
        display: block;
    }

    .first-description-below {
        display: none;
    }

    .tablet-br {
        display: none;
    }

    .small-desk-break {
        display: block;
    }

    .small-desk-breaks {
        display: none
    }

}


@media screen and (max-width: 390px) {
    .rough-draft-card {
        width: 100%;
        height: 330px;
        padding: 20px;
    }

    .rough-draft-monster {
        width: 120px;
        height: 94px;
    }
}

@media screen and (max-width: 375px) {
    .rough-draft-card {
        width: 100%;
        height: 300px;
    }

    .rough-draft-monster {
        width: 120px;
        height: 94px;
    }
}